import { Link } from "gatsby"
import React, {useState} from "react"
import {MobileNavigation} from "./navigation"
import styled from "styled-components"
import Logo from "../images/maino-logo.svg"
import BackgroundImage from "gatsby-background-image"
import MenuIcon from "../images/menu-icon.svg";
import {LogoWrapper} from "./header";

const HeaderTitle = styled.header`
  color: #fff;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 60px;
  text-align: center;
  line-height: 72px;
  margin-bottom: 40px;
  @media (max-width: 991px){
      font-size: 40px !important;
  }
  
  @media (max-width: 768px){
    margin-top: 60px !important;
    font-size: 30px !important;
  }
`

const SmallHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .gatsby-image-wrapper {
    width: 100%;
    height: 435px;
  }
`


const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SmallHeader = ({ title = "", image = "" }) => {
  const [mobileNav, showMobileNav] = useState(false);
  const toggleNav = () => {console.log("K"); showMobileNav(!mobileNav); }
  return (
    <SmallHeaderWrapper>
      <BackgroundImage Tag="section" fluid={image} backgroundColor={`#040e18`}>
        <Overlay>
          <LogoWrapper>
            <Link to={"/"}>
              <Logo/>
            </Link>
            <button onClick={toggleNav} className={`mobile-menu-trigger ${mobileNav ? "visible" : "hidden"}`}>
              <MenuIcon />
            </button>
          </LogoWrapper>
          {mobileNav && <MobileNavigation/>}
          <HeaderTitle>{title}</HeaderTitle>
        </Overlay>
      </BackgroundImage>
    </SmallHeaderWrapper>
  )
}

export default SmallHeader
