import {graphql, Link, useStaticQuery} from "gatsby"
import PropTypes from "prop-types"
import React, {useState} from "react"
import Navigation, {MobileNavigation} from "./navigation"
import styled, {css} from "styled-components"
import StyledHeaderBackground from "./headerBackground"
import Img from "gatsby-image"
import Logo from "../images/maino-logo.svg"
import {Button} from "./button"
import MenuIcon from "../images/menu-icon.svg";
const HeaderTitle = styled.h1`
  color: #fff;
  font-family: "helvetica neue", sans-serif;
  font-size: 60px;
  text-align: center;
  line-height: 72px;
  margin-bottom: 40px;
  @media (max-width: 991px){
      font-size: 40px !important;
  }
`

const HeaderBox = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  min-height: 605px;
   @media (max-width: 767px){
    min-height: 300px !important;
  }
  overflow: hidden;
  position: relative;
  @media (max-width: 767px){
    min-height: 300px !important;
  }
      max-width: 1440px;
      margin: 0 auto;
  .gatsby-image-wrapper {
    width: 250px;
    margin-right: 60px;
    top: 40px;
  }
  
  @media all and (-ms-high-contrast:none)
     {
     // fixes iphone position on ie 11
     *::-ms-backdrop, .gatsby-image-wrapper {top: 460px !important; } /* IE11 */
     }
`

export const LogoWrapper = styled.div`
  position: absolute;
  z-index: 20;
  top: 0;
  right: 0;
  background: #000;
  display: flex;
  justify-content: space-between;
  svg {
    width: 240px;
  }
  .mobile-menu-trigger {
    text-align: right;
    justify-content: center;
    align-items: center;
    min-width: 80px;
    background:transparent;
    border:none;
    outline:none;
    svg {
    width: 32px;
    height: 32px;
    }
    display: none;
  }
  padding: 10px 0;
   @media (max-width: 991px){
      width: 100%;
      svg {
          width: 200px;
          height: 60px;
      }
      .mobile-menu-trigger {
          display: flex;
          svg {
            path {
              transition: transform ease-in-out 0.2s;
            }
          }
          &.visible {
            svg {
                #Mtext { display: none;}
                #Line2 {
                  transform: rotate(-45deg) translate(-6.5px,8px);
                  //d: path("M0,0.5 L12.5,12.5");
                }
                #Line1 {
                  //d: path("M0,12.5 L12.5,0.5");
                  transform: rotate(45deg) translate(2.5px,-12px);
                }
            }
          }
      }
  }
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${props =>
    props.center &&
    css`
      justify-content: center !important;
      align-items: center;
      height: 500px;
    `};

  @media (max-width: 991px) {
    &.hidden-mobile {
      display: none;
    }
    text-align: center;
    width: 100%;
  }
`

const Header = ({siteTitle}) => {
    const [mobileNav, showMobileNav] = useState(false);
    const iphone = useStaticQuery(graphql`
        query {
            leadIphone: file(relativePath: { eq: "lead-iphone.png" }) {
                childImageSharp {
                    fluid(maxWidth: 250) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    const toggleNav = () => {showMobileNav(!mobileNav); }

    return (
        <header>
            <StyledHeaderBackground>
                <LogoWrapper>
                    <Link to={"/"}>
                        <Logo/>
                    </Link>
                    <button onClick={toggleNav} className={`mobile-menu-trigger ${mobileNav ? "visible" : "hidden"}`}>
                        <MenuIcon />
                    </button>
                </LogoWrapper>
                {mobileNav && <MobileNavigation/>}

                <HeaderBox>
                    <Col center={true}>
                        <HeaderTitle>
                            Ihre persönliche
                            <br/>
                            Restaurant - App
                        </HeaderTitle>
                        <Link to={"/contact"}>
                            <Button>Jetzt digitalisieren</Button>
                        </Link>
                    </Col>
                    <Col className={"hidden-mobile"} style={{minWidth: "400px"}}>
                        <Img loading={"eager"} fluid={iphone.leadIphone.childImageSharp.fluid}/>
                    </Col>
                </HeaderBox>
            </StyledHeaderBackground>
            <Navigation/>
        </header>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
