import styled from "styled-components"

export const Button = styled.button`
  background: #760f19;
  border: none;
  color: #fff;
  font-family: inherit;
  font-size: 18px !important;
  padding: 6px 10px 10px;
  border-radius: 4px;
  text-transform: uppercase;
  &:hover {
      background-color: #9f1828;
      cursor: pointer;
  }
`

export const ButtonLink = styled.a`
  background: #760f19;
  border: none;
  color: #fff;
  font-family: inherit;
  font-size: 18px !important;
  padding: 6px 10px 10px;
  border-radius: 4px;
  text-transform: uppercase;
  &:hover {
      background-color: #9f1828;
      cursor: pointer;
  }
`

