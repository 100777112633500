/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React, {useEffect} from "react"
import PropTypes from "prop-types"
import {graphql, Link, useStaticQuery} from "gatsby"

import Header from "./header"
import "./layout.css"
import "./responsive.css"
import styled from "styled-components"
import SmallHeader from "./smallHeader"



const Main = styled.div`
  min-height: calc(100vh - 585px);
`;
const CopyrightFooter = styled.div`
  height: 75px;
  background-color: #760f19;
  color: #fff;
  text-align: center;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LegalFooter = styled.div`
  height: 75px;
  background-color: #2e2e2e;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
  }
  font-size: 20px;
  .sep {
    color: #fff;
    padding: 0 10px;
  }
`

const Layout = ({
                    smallHeader = false,
                    image = false,
                    title = false,
                    children,
                }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    useEffect(() => {
        requestAnimationFrame(() => {
            const scrollToOverview = () => {
                const overview = document.querySelector("a[name='overview']");
                if (window.location.hash === "#overview") {
                    overview.scrollIntoView();
                }
            };

            setTimeout(() => {
                scrollToOverview();
            }, 300);
        })
    }, [])

    return (
        <>
            {!smallHeader && <Header siteTitle={data.site.siteMetadata.title}/>}
            {smallHeader && <SmallHeader title={title} image={image}/>}
            <Main>{children}</Main>
            <CopyrightFooter>MainOrder Copyright &copy;</CopyrightFooter>
            <LegalFooter>
                <Link to={"/dataPolicy"}>Impressum</Link>
                <span className={"sep"}>|</span>
                <Link to={"/dataPolicy"}>Datenschutz</Link>
            </LegalFooter>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
